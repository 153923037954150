<template>
	<div class="page">
		<Head :headTitle="headTitle" />
		<div class="b-container">
			<div class="con-l">
				<div class="mod1 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">社会治理</span>
					</div>
					<div class="detail flex-11">
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">网格人员</span>
							</div>
							<div class="chart" ref="chart1"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">事件态势图</span>
							</div>
							<div class="chart" ref="chart2"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">今日主要事件</span>
							</div>
							<div class="chart" ref="chart3"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">事件接报收集</span>
							</div>


							<div class="chart" ref="chart4"></div>
						</div>
					</div>
				</div>
				<div class="mod2 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">人居环境</span>
					</div>
					<div class="detail flex-11">
						<div class="col">
							<div class="b-smtit flex flex-pack-justify">
								<span class="bt">优良空气质量天数</span>
								<span class="smwz">负氧离子浓度/cm³</span>
							</div>
							<div class="chart" ref="chart5"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">年度空气质量天数</span>
							</div>
							<div class="chart" ref="chart6"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">环境指数</span>
							</div>
							<div class="taglist">
								<div class="tag blue">环境卫生指数:88.98分</div>
								<div class="tag org">公园：3座 500㎡</div>
								<div class="tag green">空气质量指数：优</div>
								<div class="tag green">森林覆盖率：75%</div>
								<div class="tag org">噪声监控指数：55分贝</div>
							</div>
						</div>
					</div>
				</div>

				<div class="mod3 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">民生幸福</span>
					</div>
					<div class="detail flex-11">
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">教育情况</span>
							</div>
							<div class="info">
								<img src="../assets/images/city_01.svg" class="icon" alt="">
								<div class="fz">学生总人数：<span class="c-lightgreen">82,665</span>人</div>
								<div class="fz">学校总数：<span class="c-lightgreen">32</span>座</div>
							</div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">医疗情况</span>
							</div>
							<div class="info">
								<img src="../assets/images/city_02.svg" class="icon" alt="">
								<div class="fz">人均医疗费：<span class="c-lightgreen">5,422</span>元/年</div>
								<div class="fz">医院总数：<span class="c-lightgreen">45</span>座</div>
							</div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">投资情况</span>
							</div>
							<div class="chart" ref="chart7"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">人均可在支配收入</span>
							</div>
							<div class="chart" ref="chart8"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="con-c flex flex-v relative">
				<div class="mod-cen">
					<div class="cen-menu">
						<span class="lk cur">智慧城市</span>
						<span class="lk">数字乡村振兴</span>
						<span class="lk">智慧溯源</span>
						<span class="lk">智慧文旅</span>
					</div>
					<div class="cen-list">
						<div class="item item1">
							<h6 class="title">基地本情</h6>
							<div class="txt">
								基本地情：位于广西东北部、桂林西北部，全县总面积2538平方千米，辖6个镇、4个乡，119个行政村。
							</div>
							<div class="line"></div>
							<div class="circle">
								<div class="w1"></div>
								<div class="w2"></div>
								<div class="w3"></div>
								<div class="w4"></div>
							</div>
						</div>
						<div class="item item2">
							<h6 class="title">行政区划</h6>
							<div class="txt">
								<p>10乡镇</p>
								<p>119社区</p>
								<p>87网格</p>
							</div>
							<div class="line"></div>
							<div class="circle">
								<div class="w1"></div>
								<div class="w2"></div>
								<div class="w3"></div>
								<div class="w4"></div>
							</div>
						</div>
						<div class="item item3">
							<h6 class="title">人口概况</h6>
							<div class="txt">
								<p>总人口：18.6万</p>
								<p>常住人口：13.9万</p>
								<p>流出：4.7万</p>
							</div>
							<div class="line"></div>
							<div class="circle">
								<div class="w1"></div>
								<div class="w2"></div>
								<div class="w3"></div>
								<div class="w4"></div>
							</div>
						</div>
						<div class="item item4">
							<h6 class="title">组织机构</h6>
							<div class="txt">
								<p>293家企业</p>
								<p>108事业单位</p>
							</div>
							<div class="line"></div>
							<div class="circle">
								<div class="w1"></div>
								<div class="w2"></div>
								<div class="w3"></div>
								<div class="w4"></div>
							</div>
						</div>
						<div class="item item5">
							<h6 class="title">交通拥堵情况</h6>
							<span class="tag">畅通</span>
							<div class="line"></div>
							<div class="circle">
								<div class="w1"></div>
								<div class="w2"></div>
								<div class="w3"></div>
								<div class="w4"></div>
							</div>
						</div>
						<div class="item item6">
							<h6 class="title">气候环境</h6>
							<div class="txt">
								<p>平均18.3℃</p>
								<p>最高38.1℃</p>
								<p>最低-0.4℃</p>
							</div>
							<div class="line"></div>
							<div class="circle">
								<div class="w1"></div>
								<div class="w2"></div>
								<div class="w3"></div>
								<div class="w4"></div>
							</div>
						</div>
					</div>
				</div>


			</div>
			<div class="con-r">
				<div class="mod4 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">经济发展</span>
					</div>
					<div class="detail flex-11">
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">县城年度GDP统计</span>
							</div>
							<div class="chart" ref="chart9"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">县城季度固定资产投资额</span>
							</div>
							<div class="chart" ref="chart10"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">县城季度固定资产增速</span>
							</div>
							<div class="chart" ref="chart11"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">县城公共财政预算收入</span>
							</div>
							<div class="chart" ref="chart12"></div>
						</div>
					</div>
				</div>
				<div class="mod5 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">公共安全</span>
					</div>
					<div class="detail flex-11">
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">实时安全事故</span>
							</div>
							<div class="scroll-list flex-1 flex flex-v">
								<div class="row-t">
									<div style="width:43%;">工厂名称</div>
									<div style="width:35%;">加工产品种类</div>
									<div style="width:22%;">产品数量</div>
								</div>
								<div class="scrollbb">
									<vue-seamless-scroll :data="scrollList" :class-option="scrollOption"
										class="scrollb">
										<div class="scrollbox flex-1">
											<div class="row" v-for="(item,index) in scrollList" :key="index">
												<div style="width:43%;">{{item.title}}</div>

												<div style="width:35%;">{{item.num}}起</div>
												<div style="width:22%;color:#FE4335;">{{item.level}}</div>
											</div>
										</div>
									</vue-seamless-scroll>
									<span class="linebg"></span>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">事件类型统计</span>
							</div>
							<div class="chart flex-11" ref="chart13"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">五年突发公共事件统计</span>
							</div>
							<div class="chart flex-11" ref="chart14"></div>
						</div>
						<div class="col">
							<div class="b-smtit flex">
								<span class="bt">事故级别统计</span>
							</div>
							<div class="chart flex-11" ref="chart15"></div>
						</div>
					</div>
				</div>
				<div class="mod6 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">政务服务</span>
					</div>
					<div class="detail flex-11">
						<div class="col col1">
							<div class="b-smtit flex">
								<span class="bt">政务工作办理情况</span>
							</div>
							<div class="box">
								<div class="grp">
									<p class="num">372</p>
									<p class="fz">受理量</p>
								</div>
								<div class="grp">
									<p class="num">186</p>
									<p class="fz">办件量</p>
								</div>
								<div class="grp">
									<p class="num">111.6</p>
									<p class="fz">办结量</p>
								</div>
							</div>
						</div>
						<div class="col col1">
							<div class="b-smtit flex">
								<span class="bt">取号情况</span>
							</div>
							<div class="box">
								<div class="grp">
									<p class="num">186</p>
									<p class="fz">大厅</p>
								</div>
								<div class="grp">
									<p class="num">74.4</p>
									<p class="fz">微信</p>
								</div>
								<div class="grp">
									<p class="num">111.6</p>
									<p class="fz">窗口</p>
								</div>
							</div>
						</div>
						<div class="col col3">
							<div class="b-smtit flex">
								<span class="bt">大厅综合窗口等待时间</span>
							</div>
							<div class="box">
								<div class="day c-red">周一：8小时</div>
								<div class="day c-red">周二：8小时</div>
								<div class="day">周三：7小时</div>
								<div class="day">周四：6小时</div>
								<div class="day c-red">周五：8小时</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Head from '@/components/head_big.vue'
	let Echarts = require('echarts/lib/echarts');
	import {
		loopShowTooltip
	} from "../assets/js/tooltip-auto-show-vue.js";
	export default {
		data() {
			return {
				headTitle: '龙胜县智慧城市展示中心',
				scrollList: [{
					title: '县城共出口交通事故',
					num: '1',
					level: '紧急'
				}, {
					title: '食品加工厂消防事故',
					num: '2',
					level: '紧急'
				}, {
					title: '县卫生院卫生事故',
					num: '1',
					level: '紧急'
				}, {
					title: '村桥段边坡',
					num: '1',
					level: '紧急'
				}],
				chart1: null,
				tootipTimer1: "",
				chart2: null,
				tootipTimer2: "",
				chart3: null,
				tootipTimer3: "",
				chart4: null,
				tootipTimer4: "",
				chart5: null,
				tootipTimer5: "",
				chart6: null,
				tootipTimer6: "",
				chart7: null,
				tootipTimer7: "",
				chart8: null,
				tootipTimer8: "",
				chart9: null,
				tootipTimer9: "",
				chart10: null,
				tootipTimer10: "",
				chart11: null,
				tootipTimer11: "",
				chart12: null,
				tootipTimer12: "",
				chart13: null,
				tootipTimer13: "",
				chart14: null,
				tootipTimer14: "",
				chart15: null,
				tootipTimer15: "",
			}
		},
		components: {
			Head

		},
		computed: {
			scrollOption() {
				return {
					step: 0.3, // 数值越大速度滚动越快
					// limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					// openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		created() {

		},
		mounted() {
			this.init1();
			this.init2();
			this.init3();
			this.init4();
			this.init5();
			this.init6();
			this.init7();
			this.init8();
			this.init9();
			this.init10();
			this.init11();
			this.init12();
			this.init13();
			this.init14();
			this.init15();
			window.addEventListener('resize', () => {
				this.chart1.resize();
				this.chart2.resize();
				this.chart3.resize();
				this.chart4.resize();
				this.chart5.resize();
				this.chart6.resize();
				this.chart7.resize();
				this.chart8.resize();
				this.chart9.resize();
				this.chart10.resize();
				this.chart11.resize();
				this.chart12.resize();
				this.chart13.resize();
				this.chart14.resize();
				this.chart15.resize();
			});
		},

		methods: {
			init1() {
				this.chart1 = Echarts.init(this.$refs.chart1);
				const chartData = [{
						value: 186,
						name: '一级网格',
					},
					{
						value: 279,
						name: '二级网格'
					},
					{
						value: 465,
						name: '三级网格'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: '{a|已上报}\n\n{b|' + sum + '}',
						top: 'center',
						textStyle: {
							rich: {
								a: {
									fontSize: nowSize(20),
									padding: [0, 0, 0, nowSize(105)],
									color: '#fff',
									lineHeight: 1,
									fontFamily: 'YouSheBiaoTiHei',
								},
								b: {
									fontSize: nowSize(18),
									padding: [nowSize(10), 0, 0, nowSize(110)],
									color: '#45F5FF',
									lineHeight: 1,
									fontFamily: 'YouSheBiaoTiHei'
								}
							}
						}
					},
					tooltip: {
						trigger: 'item',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						},
						position(point, params, dom, rect, size) {}
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(12),
						itemHeight: nowSize(12),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(16),
							lineHeight: nowSize(30)
						},
						itemGap: nowSize(15),
						formatter: name => {
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '项}' + '{percent|' + p + '%}'
						},
						textStyle: {
							rich: {
								name: {
									fontSize: nowSize(16),
									width: nowSize(100),
									height: nowSize(20),
									padding: [0, 0, 0, 5],
									color: '#fff',
								},
								value: {
									fontSize: nowSize(16),
									width: nowSize(90),
									height: nowSize(20),
									color: '#45F5FF',
								},
								percent: {
									fontSize: nowSize(16),
									//width: nowSize(110),
									height: nowSize(20),
									color: '#fff',
								},
							}
						}
					},
					color: ['#fc9d1f', '#3478ff', '#03b581'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['65%', '75%'],
						center: ['25%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#fff',
							borderWidth: 0
						},
						labelLine: {
							show: false
						},
						data: chartData
					}, {
						type: 'pie',
						name: '内层细圆环',
						radius: ['55%', '58%'],
						center: ['25%', '48%'],
						hoverAnimation: false,
						clockWise: true,
						itemStyle: {
							normal: {
								color: '#178ac9',
							},
						},
						tooltip: {
							show: false,
						},
						label: {
							show: false,
						},
						data: [1],
					}]

				};
				this.chart1.setOption(option, true);

				this.tootipTimer1 && this.tootipTimer1.clearLoop();
				this.tootipTimer1 = 0;
				this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
			},


			init2() {
				this.chart2 = Echarts.init(this.$refs.chart2);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(10),
						right: nowSize(15),
						bottom: nowSize(5),
						top: nowSize(15),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['杭州', '上海', '湖州', '苏州', '无锡', '南京', ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						max: 12000,
						interval: 3000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(16),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
						},
						showBackground: false,
						barWidth: nowSize(10),
						data: [10046, 1340, 1340, 2009, 6698, 6698],
						itemStyle: {
							normal: {
								barBorderRadius: 0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#024570'
								}, {
									offset: 1,
									color: '#45F5FF'
								}]),
							}
						}
					}]

				};
				this.chart2.setOption(option, true);

				this.tootipTimer2 && this.tootipTimer2.clearLoop();
				this.tootipTimer2 = 0;
				this.tootipTimer2 = loopShowTooltip(this.chart2, option, {});

			},


			init3() {
				this.chart3 = Echarts.init(this.$refs.chart3);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(10),
						right: nowSize(15),
						bottom: nowSize(5),
						top: nowSize(20),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['民生服务', '民意收集', '矛盾纠纷', '社会治安', '刑事案件', ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						max: 80,
						interval: 20,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(16),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
						},
						showBackground: false,
						barWidth: nowSize(10),
						data: [70, 42, 14, 8, 1],
						itemStyle: {
							normal: {
								barBorderRadius: 0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#024570'
								}, {
									offset: 1,
									color: '#45F5FF'
								}]),
							}
						}
					}]

				};
				this.chart3.setOption(option, true);

				this.tootipTimer3 && this.tootipTimer3.clearLoop();
				this.tootipTimer3 = 0;
				this.tootipTimer3 = loopShowTooltip(this.chart3, option, {});

			},





			init4() {
				this.chart4 = Echarts.init(this.$refs.chart4);
				const chartData = [{
						value: 4151,
						name: '已处理',
					},
					{
						value: 2767,
						name: '未处理'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: '{a|已上报}\n\n{b|' + sum + '}',
						top: 'center',
						textStyle: {
							rich: {
								a: {
									fontSize: nowSize(20),
									padding: [0, 0, 0, nowSize(105)],
									color: '#fff',
									lineHeight: 1,
									fontFamily: 'YouSheBiaoTiHei',
								},
								b: {
									fontSize: nowSize(18),
									padding: [nowSize(10), 0, 0, nowSize(110)],
									color: '#45F5FF',
									lineHeight: 1,
									fontFamily: 'YouSheBiaoTiHei'
								}
							}
						}
					},
					tooltip: {
						trigger: 'item',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						},
						position(point, params, dom, rect, size) {}
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(12),
						itemHeight: nowSize(12),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(16),
							lineHeight: nowSize(30)
						},
						itemGap: nowSize(15),
						formatter: name => {
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '项}' + '{percent|' + p + '%}'
						},
						textStyle: {
							rich: {
								name: {
									fontSize: nowSize(16),
									width: nowSize(80),
									height: nowSize(20),
								 padding: [0, 0, 0, 5],
									color: '#fff',
								},
								value: {
									fontSize: nowSize(16),
									width: nowSize(110),
									height: nowSize(20),
									color: '#45F5FF',
								},
								percent: {
									fontSize: nowSize(16),
									//width: nowSize(110),
									height: nowSize(20),
									color: '#fff',
								},
							}
						}
					},
					color: ['#fc9d1f', '#3478ff'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['65%', '75%'],
						center: ['25%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#fff',
							borderWidth: 0
						},
						labelLine: {
							show: false
						},
						data: chartData
					}, {
						type: 'pie',
						name: '内层细圆环',
						radius: ['55%', '58%'],
						center: ['25%', '48%'],
						hoverAnimation: false,
						clockWise: true,
						itemStyle: {
							normal: {
								color: '#178ac9',
							},
						},
						tooltip: {
							show: false,
						},
						label: {
							show: false,
						},
						data: [1],
					}]

				};
				this.chart4.setOption(option, true);

				this.tootipTimer4 && this.tootipTimer4.clearLoop();
				this.tootipTimer4 = 0;
				this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
			},

			init5() {
				this.chart5 = Echarts.init(this.$refs.chart5);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3378FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(10),
						right: nowSize(30),
						bottom: nowSize(5),
						top: nowSize(10),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月', ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: 'rgba(51,120,255,.2)' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						//max: 30000,
						interval: 1000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(16),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['rgba(51,120,255,.2)'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'line',
						data: [4000, 4040, 4240, 4080, 4320, 4400, 3840, 3920, 3440, 4280, 3600, 3680],
						markPoint: {
							data: [{
									type: 'max',
									name: 'Max',
									symbol: 'image://' + require('../assets/images/city_05.png'),
									symbolSize: nowSize(30),
									label: {
										show: false
									},
							},
								{
									type: 'min',
									name: 'Min',
									symbol: 'image://' + require('../assets/images/city_04.png'),
									symbolSize: nowSize(30),
									label: {
										show: false
									},
								}
							]
						},
						smooth: true,
						symbolSize: 1, //折线点的大小
						itemStyle: {
							normal: {
								color: "#3378FF", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#3378FF" //折线的颜色

								},
								areaStyle: {
									color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
										offset: 0,
										color: 'rgba(51,120,255,0)'
									}, {
										offset: 1,
										color: 'rgba(51,120,255,.6)'
									}]),
								}

							}
						}
					}]

				};
				this.chart5.setOption(option, true);

				this.tootipTimer5 && this.tootipTimer5.clearLoop();
				this.tootipTimer5 = 0;
				this.tootipTimer5 = loopShowTooltip(this.chart5, option, {});

			},

			init6() {
				this.chart6 = Echarts.init(this.$refs.chart6);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(10),
						right: nowSize(10),
						bottom: nowSize(5),
						top: nowSize(20),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['2017年', '2018年', '2019年', '2020年', '2021年', ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						//max: 80,
						interval: 100,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(16),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
						},
						showBackground: false,
						barWidth: nowSize(10),
						data: [274, 292, 219, 285, 256],
						itemStyle: {
							normal: {
								barBorderRadius: 0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#024570'
								}, {
									offset: 1,
									color: '#45F5FF'
								}]),
							}
						}
					}]

				};
				this.chart6.setOption(option, true);

				this.tootipTimer6 && this.tootipTimer6.clearLoop();
				this.tootipTimer6 = 0;
				this.tootipTimer6 = loopShowTooltip(this.chart6, option, {});

			},

			init7() {
				this.chart7 = Echarts.init(this.$refs.chart7);
				const chartData = [{
						value: 1500,
						name: '未投资',
					},
					{
						value: 8500,
						name: '已投资'
					},
					{
						value: 1000,
						name: '计划投资'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					tooltip: {
						trigger: 'item',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						},
						position(point, params, dom, rect, size) {}
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(12),
						itemHeight: nowSize(12),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(16),
							lineHeight: nowSize(30)
						},
						itemGap: nowSize(15),
						formatter: name => {
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '万}' + '{percent|' + p + '%}'
						},
						textStyle: {
							rich: {
								name: {
									fontSize: nowSize(16),
									width: nowSize(80),
									height: nowSize(20),
									color: '#fff',
								},
								value: {
									fontSize: nowSize(16),
									width: nowSize(70),
								 height: nowSize(20),
									color: '#45F5FF',
								},
								percent: {
									fontSize: nowSize(16),
									//width: nowSize(110),
									height: nowSize(20),
									color: '#fff',
								},
							}
						}
					},
					color: ['#03b581', '#3478ff', '#fc9d1f'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['60%', '70%'],
						center: ['22%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#fff',
							borderWidth: 0
						},
						labelLine: {
							show: false
						},
						data: chartData
					}, ]
				};
				this.chart7.setOption(option, true);

				this.tootipTimer7 && this.tootipTimer7.clearLoop();
				this.tootipTimer7 = 0;
				this.tootipTimer7 = loopShowTooltip(this.chart7, option, {});
			},

			init8() {
				this.chart8 = Echarts.init(this.$refs.chart8);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(10),
						right: nowSize(10),
						bottom: nowSize(5),
						top: nowSize(20),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月', ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						max: 4000,
						interval: 1000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(16),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
						},
						showBackground: false,
						barWidth: nowSize(10),
						data: [3140, 3349, 1675, 1842, 1884, 1926, 2721, 1842, 1905, 1947, 1988, 2070],
						itemStyle: {
							normal: {
								barBorderRadius: 0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#024570'
								}, {
									offset: 1,
									color: '#45F5FF'
								}]),
							}
						}
					}]

				};
				this.chart8.setOption(option, true);

				this.tootipTimer8 && this.tootipTimer8.clearLoop();
				this.tootipTimer8 = 0;
				this.tootipTimer8 = loopShowTooltip(this.chart8, option, {});

			},

			init9() {
				this.chart9 = Echarts.init(this.$refs.chart9);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(5),
						right: nowSize(10),
						bottom: nowSize(5),
						top: nowSize(20),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月', ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						interval: 20000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(20),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(14)
							},
						},
						showBackground: false,
						barWidth: nowSize(10),
						data: [82688, 88200, 44100, 48510, 49612, 50715, 71662, 48510, 50164, 51266, 52369,
							54574
						],
						itemStyle: {
							normal: {
								barBorderRadius: 0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#024570'
								}, {
									offset: 1,
									color: '#45F5FF'
								}]),
							}
						}
					}]

				};
				this.chart9.setOption(option, true);

				this.tootipTimer9 && this.tootipTimer9.clearLoop();
				this.tootipTimer9 = 0;
				this.tootipTimer9 = loopShowTooltip(this.chart9, option, {});

			},

			init10() {
				this.chart10 = Echarts.init(this.$refs.chart10);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(5),
						right: nowSize(10),
						bottom: nowSize(5),
						top: nowSize(20),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['第一季', '第二季', '第三季', '第四季', ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						interval: 50000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(18),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(14)
							},
						},
						showBackground: false,
						barWidth: nowSize(10),
						data: [126832, 183202, 169110, 140925],
						itemStyle: {
							normal: {
								barBorderRadius: 0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#024570'
								}, {
									offset: 1,
									color: '#45F5FF'
								}]),
							}
						}
					}]

				};
				this.chart10.setOption(option, true);

				this.tootipTimer10 && this.tootipTimer10.clearLoop();
				this.tootipTimer10 = 0;
				this.tootipTimer10 = loopShowTooltip(this.chart10, option, {});

			},

			init11() {
				this.chart11 = Echarts.init(this.$refs.chart11);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(10),
						right: nowSize(30),
						bottom: nowSize(5),
						top: nowSize(10),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月', ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						//max: 30000,
						interval: 2000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(16),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'line',
						data: [5073, 6088, 7610, 9639, 4059, 10147, 8117, 5581, 9132, 8625, 2537],
						smooth: true,
						symbolSize: 1, //折线点的大小
						itemStyle: {
							normal: {
								color: "#FD9D20", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#FD9D20" //折线的颜色

								},
								areaStyle: {
									color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
										offset: 0,
										color: 'rgba(235,157,32,0)'
									}, {
										offset: 1,
										color: 'rgba(235,157,32,.6)'
									}]),
								}

							}
						}
					}]

				};
				this.chart11.setOption(option, true);

				this.tootipTimer11 && this.tootipTimer11.clearLoop();
				this.tootipTimer11 = 0;
				this.tootipTimer11 = loopShowTooltip(this.chart11, option, {});

			},

			init12() {
				this.chart12 = Echarts.init(this.$refs.chart12);
				const chartData = [{
						value: 93437,
						name: '上月出口',
					},
					{
						value: 373748,
						name: '上月进口'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: '{a|财政收入}\n\n{b|' + sum + '}',
						top: 'center',
						textStyle: {
							rich: {
								a: {
									fontSize: nowSize(20),
									padding: [0, 0, 0, nowSize(95)],
									color: '#fff',
									lineHeight: 1,
									fontFamily: 'YouSheBiaoTiHei',
								},
								b: {
									fontSize: nowSize(18),
									padding: [nowSize(10), 0, 0, nowSize(93)],
									color: '#45F5FF',
									lineHeight: 1,
									fontFamily: 'YouSheBiaoTiHei'
								}
							}
						}
					},
					tooltip: {
						trigger: 'item',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						},
						position(point, params, dom, rect, size) {}
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(12),
						itemHeight: nowSize(12),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(16),
							lineHeight: nowSize(30)
						},
						itemGap: nowSize(15),
						formatter: name => {
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '亿元}' + '{percent|' + p + '%}'
						},
						textStyle: {
							rich: {
								name: {
									fontSize: nowSize(16),
									width: nowSize(90),
									height: nowSize(20),
								 padding: [0, 0, 0, 5],
									color: '#fff',
								},
								value: {
									fontSize: nowSize(16),
									width: nowSize(120),
									height: nowSize(20),
									color: '#45F5FF',
								},
								percent: {
									fontSize: nowSize(16),
									//width: nowSize(110),
									height: nowSize(20),
									color: '#fff',
								},
							}
						}
					},
					color: ['#3478ff', '#fc9d1f'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['65%', '78%'],
						center: ['25%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#fff',
							borderWidth: 0
						},
						labelLine: {
							show: false
						},
						data: chartData
					}, {
						type: 'pie',
						name: '内层细圆环',
						radius: ['55%', '58%'],
						center: ['25%', '48%'],
						hoverAnimation: false,
						clockWise: true,
						itemStyle: {
							normal: {
								color: '#178ac9',
							},
						},
						tooltip: {
							show: false,
						},
						label: {
							show: false,
						},
						data: [1],
					}]

				};
				this.chart12.setOption(option, true);

				this.tootipTimer12 && this.tootipTimer12.clearLoop();
				this.tootipTimer12 = 0;
				this.tootipTimer12 = loopShowTooltip(this.chart12, option, {});
			},

			init13() {
				this.chart13 = Echarts.init(this.$refs.chart13);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(5),
						right: nowSize(10),
						bottom: nowSize(5),
						top: nowSize(20),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['民生服务', '民意收集', '矛盾纠纷', '社会治安', '刑事案件'],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						max: 80,
						interval: 20,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(18),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(14)
							},
						},
						showBackground: false,
						barWidth: nowSize(10),
						data: [70, 42, 14, 8, 1, ],
						itemStyle: {
							normal: {
								barBorderRadius: 0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#024570'
								}, {
									offset: 1,
									color: '#45F5FF'
								}]),
							}
						}
					}]

				};
				this.chart13.setOption(option, true);

				this.tootipTimer13 && this.tootipTimer13.clearLoop();
				this.tootipTimer13 = 0;
				this.tootipTimer13 = loopShowTooltip(this.chart13, option, {});

			},

			init14() {
				this.chart14 = Echarts.init(this.$refs.chart14);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(5),
						right: nowSize(10),
						bottom: nowSize(5),
						top: nowSize(20),
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['2017年', '2018年', '2019年', '2020年', '2021年'],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(16)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						//max:80,
						interval: 2000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(18),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(16), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],

					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(14)
							},
						},
						showBackground: false,
						barWidth: nowSize(10),
						data: [3720, 2790, 930, 9300, 5580],
						itemStyle: {
							normal: {
								barBorderRadius: 0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#024570'
								}, {
									offset: 1,
									color: '#45F5FF'
								}]),
							}
						}
					}]

				};
				this.chart14.setOption(option, true);

				this.tootipTimer14 && this.tootipTimer14.clearLoop();
				this.tootipTimer14 = 0;
				this.tootipTimer14 = loopShowTooltip(this.chart14, option, {});

			},

			init15() {
				this.chart15 = Echarts.init(this.$refs.chart15);
				const chartData = [{
						value: 3627,
						name: '一般事故',
					},
					{
						value: 1116,
						name: '较大事故'
					},
					{
						value: 558,
						name: '重大事故'
					},
					{
						value: 279,
						name: '特别重大事故'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					tooltip: {
						trigger: 'item',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						},
						position(point, params, dom, rect, size) {}
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(12),
						itemHeight: nowSize(12),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(16),
							lineHeight: nowSize(30)
						},
						itemGap: nowSize(15),
						formatter: name => {
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '项}' + '{percent|' + p + '%}'
						},
						textStyle: {
							rich: {
								name: {
									fontSize: nowSize(16),
									width: nowSize(110),
									height: nowSize(20),
								 padding: [0, 0, 0, 5],
									color: '#fff',
								},
								value: {
									fontSize: nowSize(16),
									width: nowSize(95),
									height: nowSize(20),
									color: '#45F5FF',
								},
								percent: {
									fontSize: nowSize(16),
									//width: nowSize(110),
									height: nowSize(20),
									color: '#fff',
								},
							}
						}
					},
					color: ['#03b581', '#3478ff', '#fc9d1f', '#fe4334'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['60%', '75%'],
						center: ['25%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#fff',
							borderWidth: 0
						},
						labelLine: {
							show: false
						},
						data: chartData
					}]

				};
				this.chart15.setOption(option, true);

				this.tootipTimer15 && this.tootipTimer15.clearLoop();
				this.tootipTimer15 = 0;
				this.tootipTimer15 = loopShowTooltip(this.chart15, option, {});
			},

		}
	}
</script>
<style scoped>
.page{background:url(../assets/images/city_03.jpg) no-repeat center;background-size:100% 100%;}
	
.mod1{height: 45%;margin-bottom: 1%;}	
.mod2{height: 26.5%;margin-bottom: 1%;}		
.mod3{height: 26.5%;}	

.mod4{height: 40%;margin-bottom: 1%;}	
.mod5{height: 40%;margin-bottom: 1%;}		
.mod6{height: 18%;}	



.mod1{}
.mod1 .detail{width: 100%; display: grid;grid-template-columns: 1fr 1fr;grid-gap: 20px;grid-template-rows: 1fr 1fr;}
.mod1 .chart{height:calc(100% - 40px);}

.mod2 .detail{width: 100%; display: grid;grid-template-columns: 1fr 1fr 1fr;grid-gap: 15px;}
.mod2 .col{height:100%;}
.mod2 .chart{height:calc(100% - 40px);}

.mod2 .taglist{margin-left: -15px;}
.mod2 .taglist .tag{display: inline-block;vertical-align: middle;padding:0 20px;margin-left: 15px;border-radius:16px;line-height: 30px;font-size: 16px;color:#fff;margin-bottom: 15px;}
.mod2 .taglist .tag.blue{box-shadow: inset 0 0 5px 2px #0067ce;border: 1px solid #0067ce;}
.mod2 .taglist .tag.org{box-shadow: inset 0 0 5px 2px #fd9d20;border: 1px solid #C98E17;}
.mod2 .taglist .tag.green{box-shadow: inset 0 0 5px 2px #05eb89;border: 1px solid #17C99C;}

.mod2 .b-smtit .smwz{font-size: 12px;}



.mod3{width:calc(100% + 30px);}
.mod3 .detail{width: 100%; display: grid;grid-template-columns: 1fr 1fr 1.8fr 1.8fr;grid-gap: 10px;}
.mod3 .col{height:100%;}
.mod3 .chart{height:calc(100% - 40px);}

.mod3 .info{}
.mod3 .info .icon{height: 140px;width: auto;display: block;margin:0 auto;margin-top: -20px;}
.mod3 .info .fz{font-size: 16px;color:#fff;text-align: center;margin-bottom: 20px;margin-top: -15px;}
.mod3 .info .fz:last-child{margin-bottom: 0;}


.con-c{position:relative;}
.cen-list{display: flex;align-items: flex-end;justify-content: space-between;position:absolute;width: 100%;left: 0;bottom: 0;}
.cen-list .item{text-align: center;max-width:17%;position:relative;}
.cen-list .title{font-size: 22px;color:#45F5FF;font-family: 'RuiZiZhenYan';margin-bottom: 8px;}
.cen-list .txt{font-size: 16px;color:#fff;line-height: 1.5;margin-bottom: 10px;}
.cen-list .tag{display: inline-block;vertical-align: middle;padding:0 10px;min-width:80px;text-align: center;line-height: 30px;text-align: center;box-shadow: inset 0 0 5px 2px #05eb89;border: 1px solid #17C99C;font-size: 16px;color:#17C99C;border-radius:18px;margin-bottom: 10px;}
.cen-list .line{background: linear-gradient(180deg,#FFDA00 0%,rgba(255,221,29,0) 100%);border-radius:8px;width: 5px;height: 280px;margin:0 auto;}

.cen-list .circle{position: absolute;transform: rotateX(260deg);left: 50%;bottom: 0;}
.cen-list .circle>div{position: absolute;left: -90px;bottom: 0;width: 180px;height: 180px;display: flex;align-items: center;justify-content: center;}
.cen-list .circle>div::after{content: "";border-radius: 50%;animation: ripple 4s linear infinite;}	

.cen-list .item1{margin-bottom: 18%;}
.cen-list .item2{margin-bottom: 34%;}
.cen-list .item3{margin-bottom: 28%;}
.cen-list .item4{margin-bottom: 10%;}
.cen-list .item5{margin-bottom: 35%;}
.cen-list .item6{margin-bottom: 18%;}

.cen-list .circle .w1::after{animation-delay: 0s;}	
.cen-list .circle .w2::after{animation-delay: 1s;}	
.cen-list .circle .w3::after{animation-delay: 2s;}	
.cen-list .circle .w4::after{animation-delay: 3s;}	
	
	


@keyframes ripple{
	0%{
		opacity:1;
		width:0rem;
		height:0rem;
		background:rgba(255,218,0,.6)
	}
	to{
		opacity:0;
		width:180px;
		height:180px;
		background:rgba(255,218,0,.6)
	}
}
	
	
	
.mod4 .detail{width: 100%; display: grid;grid-template-columns: 1fr 1fr;grid-row-gap: 5px;grid-column-gap: 5%;grid-template-rows: 1fr 1fr;}
.mod4 .col{}
.mod4 .chart{height:calc(100% - 40px);}

.mod5 .detail{width: 100%; /* display: grid;grid-template-columns: 1fr 1fr;grid-row-gap: 5px;grid-column-gap: 5%;grid-template-rows: 1fr 1fr; */height:calc(100% - 65px);display: flex;flex-wrap: wrap;justify-content: space-between;align-items: stretch;}
.mod5 .col{height: 46%;width: 47.5%;margin-bottom: 3%;}
.mod5 .chart{height:calc(100% - 40px);}
.mod5 .scroll-list{height:calc(100% - 40px);}

.mod6{}
.mod6 .detail{width: 100%; display: grid;grid-template-columns: 1.5fr 1fr 2fr;grid-gap: 20px;}
.mod6 .col{height:100%;display: flex;flex-direction: column;}
.mod6 .box{background-color: #071d41;padding:5px 5px;flex:1;overflow: hidden;display: flex;align-items: center;}
.mod6 .grp{text-align: center;}
.mod6 .grp .num{font-size: 24px;font-weight: bold;color:#45F5FF;}
.mod6 .grp .fz{font-size: 20px;color:#fff;}

.mod6 .day{font-size: 18px;color:#fff;width: 33.3%;}
.mod6 .col1 .box{justify-content: space-around; }
.mod6 .col3 .box{flex-wrap: wrap;padding-left:20px;}




</style>